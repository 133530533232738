import React, { useState, useEffect } from "react";
import "./App.css";
import { Button, Container, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormItem from './components/FormItem';
import ItemsSection from "./components/ItemsSection";
import AppFooter from "./components/AppFooter";
import 'bootstrap/dist/css/bootstrap.min.css';

import { faQrcode } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import OwedSection from "./components/OwedSection";
import OCRModal from "./components/OCRModal";

// Add the QR code icon to the library
library.add(faQrcode);

/**
 * App entry component.
 */
function App() {
  const [items, setItems] = useState([]);
  const [dues, setDues] = useState([]);
  const [total, setTotal] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);

  // Update the dues
  useEffect(() => {
    const subtotalDues = {};
    let subtotal = 0;

    items.forEach(item => {
      item.people.forEach(person => {
        if (!subtotalDues.hasOwnProperty(person)) {
          subtotalDues[person] = 0;
        }
        subtotalDues[person] += item.price / item.people.length;
      })
      subtotal += item.price;
    });

    const totalDues = [];

    for (const person in subtotalDues) {
      totalDues.push({
        name: person,
        cost: (subtotalDues[person] / subtotal) * total
      });
    }

    setDues(totalDues);
  }, [total, items]);

  const addItem = (text, price, people) => {
    const newItems = [...items, { text, price, people }];
    setItems(newItems);
  };

  const removeItem = index => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
  };

  const handleNewTotal = e => {
    const newTotal = e.target.value;
    if (!isNaN(parseFloat(newTotal))) {
      setTotal(parseFloat(newTotal));
    }
  }

  return (
    <div className="app">
      <div className="container">
        <h1 className="text-center mb-4 mt-4">Restaurant Bill Splitter</h1>
        <img className="main-image"
          alt={"SAO characters sitting at restaurant"}
          src={"https://otakotaku.com/asset/img/upload/2017/02/sao-ordinal-scale-wagnaria-3.jpg"} />
        <Container className="text-center mb-4 mt-4">
          <Button onClick={() => setModalOpen(true)} variant="warning">
            <FontAwesomeIcon icon="qrcode" /> Scan Receipt
          </Button>
        </Container>
        <FormItem addItem={addItem} enableRemove={false} removeItem={() => {}} initialName={""} initialCost={""} />
        <div className="total-input-div">
          <input className="total-input-box" onChange={handleNewTotal} type={"text"} placeholder="Total (Subtotal + Tax + Tip)" />
        </div>
        <ItemsSection dues={dues} items={items} removeItem={removeItem} />
        <OwedSection dues={dues} items={items} />
      </div>
      <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Scan Receipt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <OCRModal addItem={addItem} />
        </Modal.Body>
      </Modal>
      <AppFooter />
    </div>
  );
}

export default App;

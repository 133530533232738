import NoOrder from './NoOrder';

/**
 * Component showing who owes what.
 */
export default function OwedSection({ dues, items }) {
    const getRandomPerson = () => {
        let allPeople = new Set();

        items.forEach(item => {
            item.people.forEach(person => {
                allPeople.add(person);
            })
        })

        allPeople = [...allPeople];
        const randomIndex = Math.floor(Math.random() * allPeople.length);
        return allPeople[randomIndex];
    }

    const copyItemData = () => {
        const lines = [];
        dues.forEach(due => {
            lines.push(`${due.name} owes ${due.cost.toFixed(2)}`);
        });

        navigator.clipboard.writeText(lines.join('\n'))
            .then(() => {
                alert('Receipt data copied!');
            })
            .catch(() => {
                alert('Something went wrong, please copy manually :(');
            });
    }

    return (
        <div className="main-section">
            <h3 className="text-center mb-4">Who Owes What</h3>
            {dues.length === 0 && <NoOrder />}
            {dues.length > 0 &&
                <p className="venmo text-center"> If you can't decide, have <b>{getRandomPerson()}</b> pay and Venmo them. </p>
            }
            <ul className="text-center">
                {dues.map((due, index) => (
                    <li key={index}>
                        <b>{due.name}</b> owes ${due.cost.toFixed(2)}
                    </li>
                ))}
            </ul>
            {dues.length > 0 &&
                <div className="text-center mt-3 mb-3">
                    <button className="btn btn-outline-secondary" onClick={copyItemData}>
                        Copy
                    </button>
                </div>
            }
        </div>
    );
}
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import '../App.css';
import axios from 'axios';
import FormItem from "./FormItem";
import { Card } from 'react-bootstrap';

// https://mindee.com/product/receipt-ocr-api
const EXAMPLE_FILE = 'https://artsmidnorthcoast.com/wp-content/uploads/2014/05/no-image-available-icon-6.png'
const OCR_ENDPOINT = 'https://bwliang.pythonanywhere.com/ocr' // 'http://127.0.0.1:5000/ocr'

export default function OCRModal({ addItem }) {
    let [file, setFile] = React.useState(null)
    let [fileBlob, setFileBlob] = React.useState(EXAMPLE_FILE)
    let [fileUploaded, setFileUploaded] = React.useState(false)
    let [ocrStep, setOcrStep] = React.useState(1)
    let [receiptData, setReceiptData] = React.useState([])

    const uploadFile = e => {
        setFileUploaded(true);
        setFile(e.target.files[0])
        setFileBlob(URL.createObjectURL(e.target.files[0]))
    }

    // Call OCR API and set state here
    const doOcr = async () => {
        const fileData = new FormData();
        fileData.append('file', file);
            
        const res = await axios.post(OCR_ENDPOINT, fileData);
        const items = res.data.items.map((item, idx) => [item[0], item[1], idx])

        await setReceiptData(items)
        setOcrStep(3);
    }

    const processReceiptData = async e => {
        e.preventDefault();
        setOcrStep(2);
        await doOcr();
    }

    return (
        <div className="column align-items-center my-1">
            {ocrStep === 1 && 
            (<form onSubmit={processReceiptData}>
                <div className="form-group">
                    <input type="file" className="form-control" id="fileUpload" onChange={uploadFile} />
                </div>
                <div className="form-group">
                    <div className="my-3">
                        <img className="receipt-preview" src={fileBlob} />
                    </div>
                </div>
                <p> <small> We don't keep receipt data on our servers and we don't guarantee perfect itemization. </small> </p>
                <button disabled={!fileUploaded} className="btn btn-primary modal-button">Next</button>
            </form>)}
            {ocrStep === 2 && 
            (<div>
                <center className="m-2"> <small> Please wait for receipt to process... </small> </center>
                <img className={"modal-button"} src="https://i.pinimg.com/originals/fb/7a/1f/fb7a1f8c4b21618ea4e15297d9bc7789.gif" alt="Loading" />
            </div>)}
            {ocrStep === 3 && 
            (<div>
                <center> 
                    {receiptData.length === 0 && <p><i>No items available, feel free to close this window.</i></p> }
                    {receiptData.length > 0 && <p>Scan finished, please verify the items look good:</p> }
                </center>
                <ul class="no-left-padding">
                    {
                        receiptData.map(item => 
                            (<Card className={"mt-3 my-2 p-2 bg-light"}>
                                {/* BUG: When editing item A and adding item B, we set A back to defaults. */}
                                <FormItem 
                                    addItem={addItem}
                                    enableRemove={true}
                                    removeItem={() => { 
                                        setReceiptData(receiptData.filter(x => x[2] !== item[2]));
                                    }}
                                    initialName={item[0]}
                                    initialCost={item[1]}
                                    key={item[2]}
                                />
                            </Card>))
                    }
                </ul>
            </div>)}
        </div>
    );
}
import { useState, useRef } from "react";
import "../App.css";
import { Button, Form, Row, Col } from 'react-bootstrap';

/**
 * Form for adding a new dish.
 */
export default function FormItem({ addItem, enableRemove, removeItem, initialName, initialCost }) {
    const [name, setName] = useState(initialName);
    const [cost, setCost] = useState(initialCost);
    const [people, setPeople] = useState("");

    const [isNameInvalid, setIsNameInvalid] = useState(false);
    const [isCostInvalid, setIsCostInvalid] = useState(false);
    const [isPeopleInvalid, setIsPeopleInvalid] = useState(false);

    const nameRef = useRef(null);
    const costRef = useRef(null);
    const peopleRef = useRef(null);
    const popUpRef = useRef(null);

    // Make a list out of the string input of people, separated by comma
    const parsePeople = people => {
        const capitalized = [];

        people.split(',').forEach(person => {
            const trimmed = person.trim().toLowerCase();
            capitalized.push(trimmed.charAt(0).toUpperCase() + trimmed.slice(1));
        });

        return capitalized;
    }

    // Helper function to show the pop-up
    const showPopUp = (inputElement) => {
        // Get the position and dimensions of the input element
        const { left, top, width } = inputElement.getBoundingClientRect();

        // Position the pop-up below the input element
        popUpRef.current.style.top = `${top + window.pageYOffset + inputElement.offsetHeight}px`;
        popUpRef.current.style.left = `${left + window.pageXOffset}px`;
        popUpRef.current.style.width = `${width}px`;
    };

    // Add the item if validation succeeds
    const handleSubmit = e => {
        e.preventDefault();

        if (!name) {
            setIsNameInvalid(true);
            showPopUp(nameRef);
            return;
        }
        setIsNameInvalid(false);

        if (isNaN(parseFloat(cost))) {
            setIsCostInvalid(true);
            showPopUp(costRef);
            return;
        }
        setIsCostInvalid(false);

        if (!people) {
            setIsPeopleInvalid(true);
            showPopUp(peopleRef);
            return;
        }
        setIsPeopleInvalid(false);

        addItem(name, parseFloat(cost), parsePeople(people));
        removeItem();
        setName("");
        setCost("");
        setPeople("");
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="bill-submit-form">
                <Form.Control
                    ref={nameRef}
                    value={name}
                    className={isNameInvalid ? "input-invalid" : "input-valid"}
                    onChange={e => setName(e.target.value)}
                    placeholder="Dish (ex. Ragout Rabbit)" />
                {isNameInvalid && (
                    <div ref={popUpRef} className="pop-up">
                        Can't leave the dish name blank.
                    </div>
                )}
                <Form.Control ref={costRef} value={cost} className={isCostInvalid ? "input-invalid" : "input-valid"} onChange={e => setCost(e.target.value)} placeholder="Price (ex. 14.00)" />
                {isCostInvalid && (
                    <div ref={popUpRef} className="pop-up">
                        Cost should be a number without currency symbols (e.g. "23.00", not "$23.00").
                    </div>
                )}
                <Form.Control ref={peopleRef} value={people} className={isPeopleInvalid ? "input-invalid" : "input-valid"} onChange={e => setPeople(e.target.value)} placeholder="People Having This (ex. Kirito, Asuna)" />
                {isPeopleInvalid && (
                    <div ref={popUpRef} className="pop-up">
                        Please name at least one person having this dish (with multiple names separated by comma).
                    </div>
                )}
                <div className="mt-2 mb-3">
                    <Row>
                        <Col className="d-flex">
                            <Button className="mx-2 modal-button" variant="primary" type="submit">
                                Add
                            </Button>
                            {enableRemove &&
                            <Button className="mx-2 modal-button" variant="danger" onClick={removeItem}>
                                Delete
                            </Button>
                            }
                        </Col>
                    </Row>
                </div>
            </Form.Group>
        </Form>
    );
}


import { Container, Navbar } from 'react-bootstrap';

/**
 * Footer component in Bootstrap.
 */
export default function AppFooter() {
  return (
    <Navbar bg="light" variant="light">
      <Container>
        <Navbar.Brand style={{ fontSize: "0.8em" }}> Copyright &copy; {new Date().getFullYear()} Brandon Liang </Navbar.Brand>
        <Navbar.Text style={{ fontSize: "0.8em" }}>
          Support my website by{" "}
          <a href="https://www.paypal.com/donate/?business=UY6UNQJTZTG5J&no_recurring=0&item_name=I+hope+my+tool+saved+your+group+some+stress%21&currency_code=USD">
            donating via Paypal!
          </a>
        </Navbar.Text>
      </Container>
    </Navbar>
  );
}
import { Button } from 'react-bootstrap';
import "../App.css";

/**
 * An individual dish ordered with its info.
 * The user is able to remove dishes.
 */
export default function Item({ item, index, removeItem }) {
    return (
        <div className="item">
            <span>{item.text} - ${item.price.toFixed(2)} ({item.people.join(', ')})</span>
            <Button className="x-button" variant="outline-danger" onClick={() => removeItem(index)}>✕</Button>
        </div>
    );
}
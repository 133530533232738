import { Card } from 'react-bootstrap';
import Item from './Item';
import NoOrder from './NoOrder';

/**
 * Component showing an unordered list of dishes ordered.
 */
export default function ItemsSection({ dues, items, removeItem }) {
    return (
        <div className="main-section">
            <h3 className="text-center mb-4">Items Ordered</h3>
            {dues.length === 0 && <NoOrder />}
            {items.map((item, index) => (
                <Card key={index}>
                    <Card.Body>
                    <Item
                        item={item}
                        removeItem={removeItem}
                    />
                    </Card.Body>
                </Card>
            ))}
        </div>
    );
}